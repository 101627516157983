import React from "react";

function DelrayBeachContent() {
  return (
    <div>
      {" "}
      <div className="p-8 max-w-3xl mx-auto">
        <span className="mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-black">
          Best Hotels in Delray Beach Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-black">
            Opal Grand Oceanfront Resort & Spa 10 NOcean Blvd Delray Beach, FL
            33483 United States
          </li>

          <li className="text-lg font-medium text-black mt-8">
            The Ray Hotel Delray Beach 233 NE Second Ave Delray Beach, FL 33444
            United States
          </li>

          <li className="text-lg font-medium text-black mt-8">
            The Seagate Hotel & Spa 1000 E Atlantic Ave Delray Beach, FL 33483
            United States
          </li>
        </ul>

        <span className="mt-20 mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-black">
          Best Restaurants in Delray Beach Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-black">
            Park Tavern 32 SE Second Ave Delray Beach, FL 33444 United States
          </li>
          <li className="text-lg font-medium text-black mt-8">
            Elizabetta’s 32 E Atlantic Ave Delray Beach, FL 33444 United States
          </li>
          <li className="text-lg font-medium text-black mt-8">
            Deck 84 840 E Atlantic Ave Delray Beach, FL 33483 United States
          </li>
        </ul>
      </div>
    </div>
  );
}

export default DelrayBeachContent;
