import { FC } from "react";
import { LocationType } from "data/types";
import NcImage from "shared/NcImage/NcImage";
import locationAvatar from "images/locationavatar.png";

export interface CardCategoryBox1Props {
  className?: string;
  locationData: LocationType; // Changed from 'name' to 'locationData'
}

const CardCategoryBox1: FC<CardCategoryBox1Props> = ({
  className = "",
  locationData, // Destructure locationData
}) => {
  const { name, href } = locationData; // Access 'name' and 'href' from locationData

  const handleClick = () => {
    window.location.href = href; // Navigate to the location page
  };

  return (
    <div
      className={`nc-CardCategoryBox1 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] relative flex cursor-pointer items-center p-3 sm:p-6 ${className}`}
      data-nc-id="CardCategoryBox1"
      onClick={handleClick}
    >
      <div className="relative h-24 w-24 flex-shrink-0 overflow-hidden rounded-full">
        <NcImage src={locationAvatar} containerClassName="absolute inset-0" />
      </div>
      <div className="ml-4 flex-grow overflow-hidden">
        <h2 className="text-base font-medium">
          <span className="text-left text-base font-medium leading-6 text-black">
            {name}
          </span>
        </h2>
      </div>
    </div>
  );
};

export default CardCategoryBox1;
