import React from "react";

function PompanoBeachContent() {
  return (
    <div>
      {" "}
      <div className="p-8 max-w-3xl mx-auto">
        <span className="mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-black">
          Best Hotels in Pompano Beach Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-black">
            Marriott Fort Lauderdale / Pompano Beach Resort & Spa 1200 N Ocean
            Blvd Pompano Beach, FL 33062 United States
          </li>

          <li className="text-lg font-medium text-black mt-8">
            Home 2 Suites Hilton Pompano Beach Pier 200 N Ocean Blvd Pompano
            Beach, FL 33062 United States
          </li>

          <li className="text-lg font-medium text-black mt-8">
            Hampton Inn Fort Lauderdale / Pompano Beach 900 S Federal Hwy
            Pompano Beach, FL 33062 United States
          </li>
        </ul>

        <span className="mt-20 mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-black">
          Best Restaurants in Pompano Beach Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-black">
            Beach House Pompano 270 N Pompano Beach Boulevard Pompano Beach, FL
            33062 United States
          </li>
          <li className="text-lg font-medium text-black mt-8">
            The Foundry 2781 E Atlantic Blvd Pompano Beach, FL 33062 United
            States
          </li>
          <li className="text-lg font-medium text-black mt-8">
            Lucky Fish 222 N Pompano Beach Blvd Pompano Beach, FL 33062 United
            States
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PompanoBeachContent;
