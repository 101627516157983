import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/locationimg.png";

import HeroSearchForm2Mobile from "components/HeroSearchForm2Mobile/HeroSearchForm2Mobile";
import { globalJson } from "global/global_json";
import MobileSearch from "components/MobileSearchForm/MobileSearch";
import { HeroSearchForm } from "components/HeroSearchForm/HeroSearchForm";

export interface LocationSectionHero3Props {
  className?: string;
  name: string;
}

const LocationSectionHero3: FC<LocationSectionHero3Props> = ({ className = "", name }) => {
  return (
    <div
      className={`nc-SectionHero3 relative ${className}`}
      data-nc-id="SectionHero3"
    >
      <div className="absolute z-10 inset-x-0 mx-6 top-1/5 sm:-translate-y-1/2 md:top-1/4 md:-translate-y-1/2 lg:top-[25%] lg:translate-y-0 text-center flex flex-col items-center  space-y-2 lg:space-y-3 xl:space-y-5">
        <h2 className="font-bold text-primary-200 leading-loose text-3xl md:text-4xl lg:text-7xl bg-white bg-opacity-60 px-2 py-2 rounded-xl">
         {name}
        </h2>
        <span className="font-bold text-[#0A1E36] bg-white bg-opacity-60  leading-[2rem] text-lg md:text-2xl">
        Questions? Call now {globalJson.phone}

        </span>
        <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
          <HeroSearchForm />
        </div>
       
        {/* <ButtonPrimary
          sizeClass="px-6 py-3 lg:px-8 lg:py-4 rounded-xl"
          fontSize="text-sm sm:text-base lg:text-lg font-medium"
        >
          Keep calm & travel on
        </ButtonPrimary> */}
      </div>
      <div className="lg:hidden absolute z-10 mb-12 lg:mb-0 lg:-mt-40 top-1/3 inset-x-4 xs:mt-28 mt-20 md:mt-8">
        <MobileSearch />
      </div>
      <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8 ">
        <img
          className="absolute inset-0 object-cover"
          src={imagePng}
          alt="hero"
        />
      </div>
    </div>
  );
};

export default LocationSectionHero3;
