import React from 'react'

function SunriseContent() {
  return (
    <div>
    {" "}
    <div className="p-8 max-w-3xl mx-auto">
      <span className="mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-black">
      Best Hotels in Sunrise Florida:
      </span>
      <ul className="list-decimal">
        <li className="text-lg font-medium text-black">
        AC Hotel by Marriott Sawgrass / Sunrise 1870 Sawgrass Mills Circle Sunrise, FL 33323United States
        </li>
        
        <li className="text-lg font-medium text-black mt-8">
        Hampton Inn & Suites Sawgrass 5701 Madison Ave Tamarac, FL 33321 United States
        </li>
        
        <li className="text-lg font-medium text-black mt-8">
        DoubleTree by Hilton Sunrise / Sawgrass 13400 W Sunrise Blvd Sunrise, FL 33323 United States
        </li>
        
      </ul>

      <span className="mt-20 mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-black">
      Best Restaurants in Sunrise Florida:
      </span>
      <ul className="list-decimal">
        <li className="text-lg font-medium text-black">
        Souvlaki Street (Greek Cuisine) 9370 W Commercial Blvd Sunrise, FL 33351 United States
        </li>
        <li className="text-lg font-medium text-black mt-8">
        Souvlaki Street (Greek Cuisine) 9370 W Commercial Blvd Sunrise, FL 33351 United States
        </li>
      </ul>
    </div>
  </div>
  )
}

export default SunriseContent