import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { formatDateTime } from "utils/converSelectedDateToString";
import ButtonCircle from "shared/Button/ButtonCircle";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useData } from "data/data-provider";

export interface PayPageProps {
  className?: string;
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const {
    bookingdetails,
    selectedCar,
    pickupLocation,
    dropOffLocation,
    dropoffLocationType,
    searchedDropoffLocation,
    searchedPickupLoction,
    startDate,
    endDate,
  } = useData();
  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Congratulations 🎉
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <div>
              <h3 className="text-2xl font-semibold">
                Your booking has been confirmed.
              </h3>
              <p className="text-lg mt-3 font-semibold">{selectedCar?.name}</p>
              <p className="text-md  text-gray-500">
                {selectedCar?.plate_number}
              </p>
            </div>

            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <img
                  alt=""
                  className="absolute inset-0 object-cover w-full"
                  sizes="200px"
                  src={
                    (selectedCar?.fleet_photos?.length ?? 0) > 0
                      ? `https://fleet-management-images-upload-be.s3.amazonaws.com/${selectedCar?.fleet_photos[0].photo_url}`
                      : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <h3 className="text-2xl font-semibold">Location</h3>
            <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
              <div className="flex flex-col flex-1 p-5">
                <span className="text-sm text-neutral-400">
                  Pick up Location
                </span>
                <span className="mt-1.5 text-lg font-semibold">
                  {searchedPickupLoction
                    ? searchedPickupLoction
                    : pickupLocation?.name}
                </span>
              </div>

              <div className="flex flex-col flex-1 p-5">
                <span className="text-sm text-neutral-400">
                  Drop off Location
                </span>
                <span className="mt-1.5 text-lg font-semibold">
                  <span className="line-clamp-1">
                    {dropoffLocationType === false &&
                    searchedDropoffLocation.length > 0
                      ? searchedDropoffLocation
                      : dropOffLocation?.name
                      ? dropOffLocation?.name
                      : dropoffLocationType === true &&
                        searchedPickupLoction.length > 0
                      ? searchedPickupLoction
                      : pickupLocation?.name}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div>
            <h3 className="text-2xl font-semibold">Date & Time</h3>
            <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
              <div className="flex flex-col flex-1 p-5">
                <span className="text-sm text-neutral-400">
                  Pick up Date & Time
                </span>
                <span className="mt-1.5 text-lg font-semibold">
                {startDate ? formatDateTime(startDate) : "N/A"}
                </span>
              </div>

              <div className="flex flex-col flex-1 p-5">
                <span className="text-sm text-neutral-400">
                  Drop off Date & Time
                </span>
                <span className="mt-1.5 text-lg font-semibold">
                  <span className="line-clamp-1">
                  {endDate ? formatDateTime(endDate) : "N/A"}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <div className="flex flex-col space-y-4">
            <div className="w-full">
              <h2 className="text-xl font-medium text-gray-900 dark:text-gray-100">
                Rental Bill
              </h2>
              <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                Prices may change depending on the length of the rental and the
                price of your rental car.
              </p>
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <div>
                    <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                      Subtotal
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {" "}
                      ($
                      {(selectedCar?.price ?? 0).toFixed(2)} *{" "}
                      {bookingdetails?.trip_days} days)
                    </span>
                  </div>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${bookingdetails?.invoice?.discounted_car_total}
                  </span>
                </div>
              </div>

              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Location Total
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${bookingdetails?.invoice?.location_total}
                  </span>
                </div>
              </div>

              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Extras Total
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${bookingdetails?.invoice?.extras_total}
                  </span>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Insurance Total
                  </span>
                  <span className="lg:text-2xl sm:text-xl font-medium text-gray-900 dark:text-gray-100">
                    ${bookingdetails?.invoice?.insurance_total}
                  </span>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Tax
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${bookingdetails?.invoice?.tax}
                  </span>
                </div>
              </div>

              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Discount
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${bookingdetails?.invoice?.discount}
                  </span>
                </div>
              </div>
              <div className="mt-8 border-t border-gray-200 dark:border-gray-700 pt-4">
                <div className="flex justify-between items-center">
                  <div>
                    <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                      Total Rental Price
                    </span>
                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                      Overall price rental
                    </p>
                  </div>
                  <div>
                    <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                      $
                      {bookingdetails?.invoice
                        ?.total_price_after_coupon_without_security
                        ? bookingdetails?.invoice
                            ?.total_price_after_coupon_without_security
                        : bookingdetails?.invoice?.total_price_without_security}
                    </span>
                  </div>
                </div>
              </div>

              {bookingdetails?.invoice?.security_deposit_total !== 0 && (
                <div className="mt-4">
                  <div className="flex justify-between items-center">
                    <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                      Deposit Fee
                    </span>
                    <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                      ${bookingdetails?.invoice?.security_deposit_total}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-5">
          <ButtonPrimary href="/">Go to Home</ButtonPrimary>
          <ButtonPrimary href="/account-bookings">Go to Account</ButtonPrimary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;
