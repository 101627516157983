import React from "react";

function BocoRatonContent() {
  return (
    <div>
      {" "}
      <div className="p-8 max-w-3xl mx-auto">
        <span className="mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-black">
          Best Hotels in Boca Raton Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-black">
            The Boca Raton Hotel 501 E Camino Real Boca Raton, FL 33432 United
            States
          </li>
          <span className="text-lg font-medium text-[#898b8e]">
            By far the best hotel and resort in all of South Florida in our
            opinion. This hotel offers many amazing restaurants known worldwide-
            as well as many amenities including: golf, Spa (5 Star Rated by
            Fobes)
          </span>
          <li className="text-lg font-medium text-black mt-8">
            Hampton Inn Boca Raton- Deerfield Beach 660 WHillsboro Blvd
            Deerfield Beach, FL 33441 United States
          </li>
          <span className="text-lg font-medium text-[#898b8e]">
            Great location- right next to 95 and recently renovated. Perfect for
            business travelers not looking to be close to the beach. Rates start
            at $100 / night in the low season
          </span>
          <li className="text-lg font-medium text-black mt-8">
            Hyatt Place Boca Raton/Downtown 100 E Palmetto Park Rd Boca Raton,
            FL 33432 United States
          </li>
          <span className="text-lg font-medium text-[#898b8e]">
            Right in the heart of downtown Boca Raton right in the Mizner Park
            District- close to tons of great food and shopping options
          </span>
        </ul>

        <span className="mt-20 mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-black">
          Best Restaurants in Boca Raton Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-black">
          El Camino Mexican Soul Food 5377 Town Center Rd Unit 100 Boca Raton, FL 33486 United States
          </li>
          <li className="text-lg font-medium text-black mt-8">
          2nd Street Deli and Bagels 134 NE Second St Boca Raton, FL 33432 United States
          </li>
          <li className="text-lg font-medium text-black mt-8">
          Mister O1 Extraordinary Pizza 555 N Federal Hwy Unit 5 Boca Raton, FL 33432 United States
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BocoRatonContent;
