import React from 'react'

function CoralSpringsContent() {
  return (
    <div>
    {" "}
    <div className="p-8 max-w-3xl mx-auto">
      <span className="mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-black">
      Best Hotels in Coral Springs Florida:
      </span>
      <ul className="list-decimal">
        <li className="text-lg font-medium text-black">
        Marriott Fort Lauderdale Coral Springs Hotel & Convention Center 11775 Heron Bay Blvd Coral Springs, FL 33076 United States
        </li>
        
        <li className="text-lg font-medium text-black mt-8">
        Courtyard Fort Lauderdale 620 N University Dr Coral Springs, FL 33071 United States
        </li>
        
        
        
      </ul>

      <span className="mt-20 mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-black">
      Best Restaurants in Coral Springs Florida:
      </span>
      <ul className="list-decimal">
        <li className="text-lg font-medium text-black">
        Aruns Indian Kitchen 10278 W Sample Rd Coral Springs, FL 33065 United States
        </li>
        <li className="text-lg font-medium text-black mt-8">
        Divieto Italian 2729 N University Dr Coral Springs, FL 33065 United States
        </li>
       
      </ul>
    </div>
  </div>
  )
}

export default CoralSpringsContent